import axios from 'axios';

const ENVMODE = process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV';
export const BASEURL = ENVMODE === 'PROD' ? 'https://be.budusushi.ua/' : 'http://localhost:3004/';

export const axiosInstanceFake = axios.create({
  baseURL: BASEURL,
});

export const axiosInstance = axios.create({
  baseURL: 'https://be.budusushi.ua/',
});
